import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import Features from './components/Features';
import HowItWorks from './components/HowItWorks';
import BotsSection from './components/BotsSection';
import Testimonials from './components/Testimonials';
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import ContactForm from './components/ContactForm';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    return (
        <Router>
            <Header />
            <Routes>
                {/* Main page */}
                <Route
                    path="/"
                    element={
                        <>
                            <HeroSection />
                            <AboutUs />
                            <Features />
                            <HowItWorks />
                            <BotsSection />
                            <Testimonials />
                            <Pricing />
                            <FAQ />
                            <ContactForm />
                        </>
                    }
                />
                {/* Privacy Policy page */}
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />

                {/* Terms and Conditions page */}
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
