import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { HashLink } from 'react-router-hash-link'; // For in-page scrolling
import { Link } from 'react-router-dom'; // For standalone pages
import { useTheme } from '@mui/material/styles';

function Header() {
    const theme = useTheme();

    // Define navigation items
    const navItems = [
        { label: 'About', link: '/#about', isHash: true },
        { label: 'Features', link: '/#features', isHash: true },
        { label: 'How It Works', link: '/#how-it-works', isHash: true },
        { label: 'Bots', link: '/#bots', isHash: true },
        { label: 'Pricing', link: '/#pricing', isHash: true },
        { label: 'FAQ', link: '/#faq', isHash: true },
        { label: 'Terms & Conditions', link: '/terms-and-conditions', isHash: false },
        { label: 'Privacy Policy', link: '/privacy-policy', isHash: false },
        { label: 'Contact', link: '/#contact', isHash: true },
    ];

    return (
        <AppBar
            position="sticky"
            elevation={0}
            sx={{ backgroundColor: theme.palette.secondary.main }}
        >
            <Toolbar>
                <Typography
                    variant="h6"
                    sx={{ flexGrow: 1, color: theme.palette.common.white }}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} // Scroll to top on click
                    style={{ cursor: 'pointer' }} // Pointer cursor for indication
                >
                    SmartLabBots
                </Typography>
                <Box>
                    {navItems.map((item) => (
                        <Button
                            key={item.label}
                            component={item.isHash ? HashLink : Link} // Use HashLink for in-page scrolling
                            smooth={item.isHash ? true : undefined} // Enable smooth scrolling for HashLink
                            to={item.link}
                            sx={{ color: theme.palette.common.white }}
                            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} // Scroll to top on click
                        >
                            {item.label}
                        </Button>
                    ))}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
